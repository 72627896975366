<template>
  <footer class="text-white the-footer bg-primary flex-wrap " :class="classes">
    <cookie-law theme="blood-orange"></cookie-law>
    <div class="content-wrapper">
      <div class="top-footer">
        <vs-row class="font-medium">

          <!-- social links -->
          <vs-col class="pr-5 mb-4 right-column" vs-lg="4" vs-sm="6" vs-md="6" vs-xs="12">
            <div>
              <img class="thakaa-img" src="@/assets/images/logo/12.svg">

              <p>The World Robot Olympiad name and it is trademarks are used with permission from World Robot Olympiad
                Association Ltd.</p>

              <ul class="social-links">
                <li>
                  <a class="text-white" :href="webSettings.twitter" target="_blank">
                    <svg width="19.435" height="16.188" viewBox="0 0 19.435 16.188">
                      <path
                        d="M422.747,5934.42a7.75,7.75,0,0,1-2.533,1,3.9,3.9,0,0,0-2.91-1.292,4.037,4.037,0,0,0-3.987,4.087,4.292,4.292,0,0,0,.1.932,11.263,11.263,0,0,1-8.217-4.274,4.169,4.169,0,0,0,1.234,5.462,3.973,3.973,0,0,1-1.805-.512v.046a4.061,4.061,0,0,0,3.2,4.006,3.877,3.877,0,0,1-1.049.152,3.975,3.975,0,0,1-.751-.083,4,4,0,0,0,3.723,2.842,7.879,7.879,0,0,1-4.951,1.748,6.472,6.472,0,0,1-.952-.059,11.052,11.052,0,0,0,6.112,1.841c7.333,0,11.342-6.232,11.342-11.635l-.013-.524a8.159,8.159,0,0,0,1.993-2.12,8.005,8.005,0,0,1-2.289.652A4.136,4.136,0,0,0,422.747,5934.42Z"
                        transform="translate(-403.848 -5934.13)" fill="#fff" fill-rule="evenodd"/>
                    </svg>

                  </a>
                </li>
                <li>
                  <a class="text-white" :href="webSettings.facebook" target="_blank">
                    <svg width="8.64" height="16.188" viewBox="0 0 8.64 16.188">
                      <path
                        d="M452.031,5934.13h-2.073a3.663,3.663,0,0,0-3.833,3.983v1.84h-2.083a.322.322,0,0,0-.326.326v2.667a.33.33,0,0,0,.326.326h2.083v6.72a.323.323,0,0,0,.326.327h2.718a.33.33,0,0,0,.326-.327v-6.72h2.436a.33.33,0,0,0,.326-.326v-2.667a.32.32,0,0,0-.1-.234.342.342,0,0,0-.231-.092H449.5v-1.562c0-.745.176-1.129,1.139-1.129h1.4a.329.329,0,0,0,.326-.326v-2.48A.329.329,0,0,0,452.031,5934.13Z"
                        transform="translate(-443.715 -5934.13)" fill="#fff" fill-rule="evenodd"/>
                    </svg>

                  </a>
                </li>
                <li>
                  <a class="text-white" :href="webSettings.instagram" target="_blank">
                    <svg width="16.826" height="16.828" viewBox="0 0 16.826 16.828">
                      <path
                        d="M487.818,5950.769h-6.309a5.257,5.257,0,0,1-5.258-5.253V5939.2a5.258,5.258,0,0,1,5.258-5.262h6.309a5.258,5.258,0,0,1,5.258,5.262v6.313A5.257,5.257,0,0,1,487.818,5950.769Zm3.68-11.566a3.678,3.678,0,0,0-3.68-3.679h-6.309a3.679,3.679,0,0,0-3.681,3.679v6.313a3.676,3.676,0,0,0,3.681,3.669h6.309a3.675,3.675,0,0,0,3.68-3.669Zm-2.313-.812a.556.556,0,0,1-.56-.561.56.56,0,0,1,1.12,0A.556.556,0,0,1,489.185,5938.39Zm-4.522,8.174a4.209,4.209,0,1,1,4.207-4.2A4.208,4.208,0,0,1,484.663,5946.563Zm0-6.835a2.626,2.626,0,1,0,2.63,2.632A2.631,2.631,0,0,0,484.663,5939.729Z"
                        transform="translate(-476.251 -5933.94)" fill="#fff" fill-rule="evenodd"/>
                    </svg>

                  </a>
                </li>
                <li>
                  <a class="text-white" :href="webSettings.linkedin" target="_blank">
                    <svg width="16.177" height="16.188" viewBox="0 0 16.177 16.188">
                      <path
                        d="M530.013,5944.052v6.267h-3.468v-5.847c0-1.468-.5-2.469-1.756-2.469a1.908,1.908,0,0,0-1.778,1.327,2.582,2.582,0,0,0-.115.886v6.1h-3.469s.047-9.9,0-10.925h3.469v1.549c-.007.012-.016.022-.023.035h.023v-.035a3.417,3.417,0,0,1,3.125-1.8C528.3,5939.138,530.013,5940.7,530.013,5944.052ZM515.8,5934.13a1.887,1.887,0,1,0-.047,3.772h.023a1.887,1.887,0,1,0,.023-3.772Zm-1.756,16.188h3.466v-10.925h-3.466Z"
                        transform="translate(-513.836 -5934.13)" fill="#fff" fill-rule="evenodd"/>
                    </svg>

                  </a>
                </li>
              </ul>
            </div>
          </vs-col>

          <!-- footer links -->
          <vs-col class="center-column" vs-lg="4" vs-sm="6" vs-md="6" vs-xs="12">
            <div class="footer-links">
              <p>Links</p>
              <ul>
                <li>
                  <router-link class="text-white" to="/contactUs">Contact us</router-link>
                </li>

                <li>
                  <router-link to="/faq" class="text-white" rel="nofollow">FAQ</router-link>
                </li>

                <li>
                  <router-link
                    class="text-white"
                    to="about-us"
                    target="_blank"
                    rel="nofollow"
                  >
                    About us
                  </router-link
                  >

                </li>

                <li>
                  <router-link
                    class="text-white"
                    to="terms-and-conditions"
                    target="_blank"
                    rel="nofollow"
                  >
                    Privacy & Policy
                  </router-link
                  >
                </li>
              </ul>

            </div>
          </vs-col>

          <!-- contact details -->
          <vs-col class="left-column d-lg-flex justify-end" vs-lg="4" vs-sm="6" vs-md="6" vs-xs="12">
            <div class="">
              <img src="@/assets/images/logo/white-logo1.png"/>
              <ul>
                <!-- address -->
                <li class="align-items-start">
                  <div class="img-wrapper">
                    <img src="@/assets/images/file-icons/address-icon.png" alt="Address">
                  </div>
                  <span class="text-white">
              Address :
              <span class="fix-width-s" v-for="(item, index) in webSettingsAddress" :key="index">
                {{ item }} <br/>
              </span>
            </span>
                </li>

                <!-- email -->
                <li>
                  <div class="img-wrapper">
                    <img src="@/assets/images/file-icons/email-icon.png" alt="Address">
                  </div>
                  <span>
              <span class="text-white">
              Email :
              <a :href="`mailTo:${webSettings.email}`" class=" mr-4 text-white">
                {{ webSettings.email }}</a
              >
              </span>
            </span>
                </li>

                <!-- phone -->
                <li class="align-items-center">
                  <div class="img-wrapper">
                    <img src="@/assets/images/file-icons/phone-icon.png" alt="Address">
                  </div>
                  <span>
   <span class="text-white">
    Phone :
    <a :href="`tel:${webSettings.phone}`" class=" mr-4 text-white">
      {{ webSettings.phone }}</a
    ></span>
  </span>
                </li>
              </ul>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <vs-divider color="white"/>

    <div class="content-wrapper">
      <vs-row>
        <div
          class="flex w-full bg-img vx-row no-gutter items-center justify-center"
          style="flex-direction: column">
          <p class="text-white">
            {{ $t("COPYRIGHT") }} © {{ new Date().getFullYear() }}
            <a href="https://edu.thaka.sa/" class="text-white" target="_blank" rel="nofollow">
              WRO Virtual THAKA INTERNATIONAL DMCC
            </a>
          </p>
        </div>
      </vs-row>
    </div>
  </footer>
</template>

<script>
  import Logo from "@/layouts/components/FooterLogo.vue";
  import CookieLaw from 'vue-cookie-law'

  export default {
    name: "the-footer",
    props: {
      classes: {
        type: String
      }
    },
    data() {
      return {TechNumber: "+971502442618"};
    },
    components: {
      Logo,
      CookieLaw
    },
    computed: {
      webSettings() {
        return this.$store.state.auth.Settings;
      },
      webSettingsAddress() {
        return this.$store.state.auth.Settings.address.split(",");
      }
    },
    created() {
      this.$store.dispatch("auth/Settings");
    }
  };
</script>

<style scoped>
  .the-footer {
    padding: 0;
    /*padding: 2.2rem;*/
  }

  .top-footer {
    padding: 65px 2.2rem 35px;
  }

  .text-white {
    color: #fff !important;
  }

  .thakaa-img {
    width: 152px;
    display: block;
    margin-bottom: 20px;
  }

  .footer-links p {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 30px;
  }

  .right-column .social-links {
    margin: 0 -20px;
  }

  .right-column .social-links li {
    display: inline-block;
    margin: 0 5px;
  }

  .right-column .social-links li a {
    padding: 0 10px;
  }

  .right-column p {
    font-size: 16px;
    margin-bottom: 30px;
    max-width: 280px;
  }

  .center-column {
    display: flex;
    justify-content: center;
  }

  .center-column > div {
    /*display: inline-block;*/
  }

  .center-column li {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .left-column li {
    display: flex;
    margin-bottom: 15px;
  }

  .left-column > div > img {
    width: 166px;
    margin-bottom: 20px;
  }

  .left-column .img-wrapper {
    margin-right: 10px;
  }

  @media (max-width: 600px) {

  }

  @media (max-width: 900px) {
    .right-column, .center-column {
      margin-bottom: 30px !important;
    }
    .center-column {
      display: block;
    }
  }

  @media (min-width: 1166px) {

    .fx-tt {
      padding-right: 7rem !important;
      padding-left: 7rem !important;
    }

    footer.content-wrapper {
      padding: 0px !important;
    }
  }

</style>
